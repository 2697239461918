import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZpxTableComponent } from '../zpx-table/zpx-table.component';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { PassholderTableDataSource } from './passholder-table.datasource';
import {
  PassholderForTable,
  PASSHOLDER_TYPES,
  ZpxApiPassholderParams
} from '@src/app/models/zpx-api.model';

import { GetEnvironmentService } from '@src/app/services/get-environment/get-environment.service';
import { filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { PassholderModalComponent } from '../modals/passholder-modal/passholder-modal.component';
import { AppService } from '@src/app/app.service';
import { ZpxApiService } from '@src/app/services/zpx-api-service/zpx-api.service';
import { MatIconModule } from '@angular/material/icon';
import { ManageGroupModalComponent } from '../modals/manage-group-modal/manage-group-modal.component';
import { ImportModalComponent } from '../modals/import-modal/import-modal.component';
import { SelectedCompanyService } from '@src/app/services/selected-company/selected-company.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfirmDeactivationModalComponent } from '../modals/confirm-deactivation-modal/confirm-deactivation-modal.component';
import { ExportPassholdersService } from '@src/app/services/export-passholders/export-passholders.service';
import { TablePassholdersFilterBarService } from '@src/app/components//table-passholders-filter-bar/table-passholders-filter-bar.service';
import { DeviceDetectionService } from '@src/app/services/device-detection/device-detection.service';

@Component({
  selector: 'app-passholder-table',
  standalone: true,
  providers: [PassholderTableDataSource],
  imports: [CommonModule, ZpxTableComponent, MatIconModule],
  templateUrl: './passholder-table.component.html',
  styleUrls: ['./passholder-table.component.scss']
})
export class PassholderTableComponent implements OnInit, OnDestroy {
  constructor(
    private passholderDataSource: PassholderTableDataSource,
    private getEnvService: GetEnvironmentService,
    private dialog: MatDialog,
    private appService: AppService,
    private zpxApiService: ZpxApiService,
    public selectedCompanyService: SelectedCompanyService,
    private exportPassholdersService: ExportPassholdersService,
    private tablePassholdersFilterBarService: TablePassholdersFilterBarService,
    public deviceDetectionService: DeviceDetectionService
  ) {}

  @Input() passholderType: PASSHOLDER_TYPES = PASSHOLDER_TYPES.STUDENT;

  private onDestroy$ = new Subject<void>();
  columns$ = new BehaviorSubject(null);

  dataSource = this.passholderDataSource;

  pageSize =
    this.getEnvService.getEnvironmentProperty('paginationSettings')['pageSize'];

  selectedPassholderIds: string[] = [];
  selectionModel: SelectionModel<string> = null;
  isMobile: boolean = false;
  enableMobileTable = true;

  ngOnInit(): void {
    this.isMobile = this.deviceDetectionService.isMobile;
    this.appService.isMobile$.next(this.isMobile);
    this.zpxApiService
      .getPassholderTypes()
      .pipe(first())
      .subscribe((pTypes) => {
        const pTypeId = pTypes.find((t) => t.name === this.passholderType).id;
        this.appService.passholderTypeId$.next(pTypeId);
      });
    this.appService.passholderType$.next(this.passholderType);

    this.dataSource
      .shouldLoadData()
      .pipe(
        filter((loadData) => Boolean(loadData)),
        switchMap(() =>
          this.dataSource.populatePassholdersTable(this.passholderType)
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe();

    if (this.isMobile) {
      this.tablePassholdersFilterBarService.passholdersFilterChanged$
        .pipe(
          filter((changed) => Boolean(changed)),
          map(() => this.dataSource.mobileFiltersChanged()),
          takeUntil(this.onDestroy$)
        )
        .subscribe();
    }

    const standardColumns = this.dataSource.getStandardColumnTableModels();

    this.dataSource
      .getCustomColumnTableModels$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((customColumns) => {
        let columnsToSend = [...standardColumns, ...customColumns];

        if (this.isMobile === false) {
          // add edit column if in desktop mode
          columnsToSend.push(this.dataSource.getEditColumnTableModel());
        } else {
          // take out checkmark column if in mobile mode
          columnsToSend = columnsToSend.filter((c) => {
            return c.columnDef !== 'checkmark';
          });
        }
        this.columns$.next(columnsToSend);
      });
  }

  exportAllPassholders() {
    combineLatest([
      this.dataSource.paginationParams$,
      this.dataSource.total$,
      this.tablePassholdersFilterBarService.filterBody$
    ])
      .pipe(
        first(),
        switchMap(([params, total, body]) => {
          const paramsForCsv: ZpxApiPassholderParams = {
            ...params,
            ...body,
            limit: total
          };

          if (this.selectedPassholderIds.length) {
            paramsForCsv.passholder = this.selectedPassholderIds;
          }
          return this.exportPassholdersService.exportCsv(paramsForCsv);
        })
      )
      .subscribe(
        () => {},
        (error) => {
          console.error(error);
        }
      );
  }

  getExportButtonString() {
    if (!this.selectedPassholderIds.length) {
      return 'Export All Card History';
    }
    return 'Export Selected Card History';
  }

  onClick(passholder: PassholderForTable) {
    if (passholder.class.includes('material-icons')) {
      this.openEditPassholder(passholder);
    }
  }

  onSelect(e: SelectionModel<string>) {
    this.selectedPassholderIds = e.selected;
    this.selectionModel = e;
  }

  deactivateSelected() {
    if (this.selectedPassholderIds.length) {
      this.dialog.open(ConfirmDeactivationModalComponent, {
        data: {
          selectedPassholderIds: this.selectedPassholderIds
        }
      });
    }
  }

  openAddPassholder() {
    this.dialog.open(PassholderModalComponent, {
      data: {
        passholder: null,
        action: 'add'
      },
      width: '70vw'
    });
  }

  openEditPassholder(passholder: PassholderForTable) {
    this.dialog.open(PassholderModalComponent, {
      data: {
        passholder: {
          ...passholder
        },
        type: this.passholderType,
        action: 'edit'
      },
      width: '70vw',
      height: '85vh'
    });
  }

  openManageGroupsDialog() {
    this.dialog.open(ManageGroupModalComponent);
  }

  openImportDialog() {
    this.dialog.open(ImportModalComponent, {
      height: '85vh',
      width: '55vw',
      minWidth: '40vw'
    });
  }

  ngOnDestroy(): void {
    this.dataSource.onTableDestroy();
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.columns$.complete();
  }
}
