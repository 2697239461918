import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectedCompanyService } from '@services/selected-company/selected-company.service';
import { AppService } from '@app/app.service';

@Component({
  selector: 'app-events',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  constructor(
    public selectedCompanyService: SelectedCompanyService,
    public appService: AppService
  ) {}

  ngOnInit(): void {
    console.log('Events Page');
  }
}
