// Use this for any shared settings that cross environment bounds.
// TODO: Setup explicit permissions if applicable to your Frontend web application.
// See https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth#Permissions for details

export const permissions = {
  defaultRedirectUrl: 'unauthorized',
  defaultPermPrefix: 'zpass',
  routes: [
    ['manage', 'edit:passholder'],
    ['events', 'view:event']
  ]
};
export const appName = 'ZPass';
// Typically we would use this to report to Datadog RUM if we have it installed.
export const appVersion = '0.3.0';
